import classNames from "classnames"
import { array, func, node, number, oneOf, string } from "prop-types"
import { useMemo, useState } from "react"
import SectionFooter from "../Footer"

const SectionListGrid = ({
  className,
  wrapperClassName,
  items,
  pageUrl,
  renderItem,
  itemMore,
  maxItemsPerRow,
  initialVisibleRows,
  showAllLabel,
  gapSize,
  skeletonRows,
  children,
}) => {
  const [extended, setExtended] = useState(false)
  const [itemsOrSkeletons, setItemsOrSkeletons] = useState(null)
  const [showingSkeletons, setShowingSkeletons] = useState(true)

  const moreHiddenItemsMd =
    itemsOrSkeletons &&
    initialVisibleRows &&
    itemsOrSkeletons.length > (maxItemsPerRow - 1) * initialVisibleRows

  const moreHiddenItemsXl =
    itemsOrSkeletons &&
    initialVisibleRows &&
    itemsOrSkeletons.length > maxItemsPerRow * initialVisibleRows

  const isItemsEvenMd =
    itemsOrSkeletons && itemsOrSkeletons.length % (maxItemsPerRow - 1) === 0

  const isItemsEvenXl =
    itemsOrSkeletons && itemsOrSkeletons.length % maxItemsPerRow === 0

  const hideLastItemMd =
    isItemsEvenMd && (extended || (!initialVisibleRows && itemMore))

  const hideLastItemXl =
    isItemsEvenXl && (extended || (!initialVisibleRows && itemMore))

  useMemo(() => {
    if (!items?.length) {
      setShowingSkeletons(true)

      const rows =
        skeletonRows || (initialVisibleRows ? initialVisibleRows + 1 : 2)

      setItemsOrSkeletons([...Array(maxItemsPerRow * rows)])
      return
    }

    setShowingSkeletons(false)

    if (Array.isArray(items[0])) {
      setItemsOrSkeletons([].concat(...items))
      return
    }

    setItemsOrSkeletons(items)
  }, [
    items,
    maxItemsPerRow,
    initialVisibleRows,
    setItemsOrSkeletons,
    setShowingSkeletons,
  ])

  return (
    <div className={className}>
      <div
        className={classNames(
          "flex flex-col box-x-wrapper space-y-section",
          wrapperClassName,
        )}>
        <div
          className={classNames(
            "grid",
            gapSize === "small" ? "gap-4" : "gap-x-section gap-y-section",
            `grid-cols-${maxItemsPerRow - 2}`,
            `md:grid-cols-${maxItemsPerRow - 1}`,
            `xl:grid-cols-${maxItemsPerRow}`,
          )}>
          {children}

          {itemsOrSkeletons?.length &&
            itemsOrSkeletons.map((item, i) => {
              const isLastItem = i + 1 === itemsOrSkeletons.length

              const hidden =
                (initialVisibleRows &&
                  i >= (maxItemsPerRow - 1) * initialVisibleRows &&
                  !extended) ||
                (isLastItem && hideLastItemMd)

              const hiddenXl =
                (initialVisibleRows &&
                  i >= maxItemsPerRow * initialVisibleRows &&
                  !extended) ||
                (isLastItem && hideLastItemXl)

              if (item) {
                item.state = extended
                return (
                  <div
                    className={classNames(
                      item?.featured && "col-span-2",
                      hidden && "hidden",
                      hiddenXl ? "xl:hidden" : "xl:block",
                    )}
                    key={i}>
                    {renderItem && renderItem(item)}
                  </div>
                )
              }
            })}

          {pageUrl &&
            !showingSkeletons &&
            (!initialVisibleRows || extended) && (
              <div
                className={classNames(
                  initialVisibleRows && !moreHiddenItemsMd && "hidden",
                  initialVisibleRows && !moreHiddenItemsXl && "xl:hidden",
                )}>
                {itemMore}
              </div>
            )}
        </div>

        {initialVisibleRows && (
          <SectionFooter
            className={classNames(
              !moreHiddenItemsMd && "hidden",
              !moreHiddenItemsXl && "xl:hidden",
            )}
            onClick={() => setExtended((state) => !state)}
            extended={extended}
            showAllHref={pageUrl}
            showAllLabel={showAllLabel}
          />
        )}
      </div>
    </div>
  )
}

SectionListGrid.propTypes = {
  items: array,
  className: string,
  wrapperClassName: string,
  showAllLabel: string,
  pageUrl: string,
  renderItem: func.isRequired,
  itemMore: node,
  maxItemsPerRow: number,
  initialVisibleRows: number,
  gapSize: oneOf(["default", "small"]),
  skeletonRows: number,
  children: node,
}

SectionListGrid.defaultProps = {
  maxItemsPerRow: 4,
  gapSize: "default",
}

export default SectionListGrid
