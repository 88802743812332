const formatVideoMetadata = (video) => {
  let metadataFormatted = []

  const authors = video.authors
  if (authors.length) {
    metadataFormatted.push(`By ${authors[0]}`)
  }

  const location = video.location
  if (location.length) {
    let locationLabel = ""
    if (location[0].isPoint) {
      locationLabel = `At ${location[0].name}`
    } else {
      locationLabel = `In ${location[0].name}`
    }
    if (video.location.length > 1) {
      locationLabel += `, and more`
    }
    metadataFormatted.push(locationLabel)
  }

  if (metadataFormatted.length == 0) {
    return metadataFormatted
  }

  const year = video.tags.year
  if (year) {
    metadataFormatted.push(year)
  }

  return metadataFormatted
}
export default formatVideoMetadata
