const formatVideoDuration = (video) => {
  const toHHMMSS = (sec) => {
    var sec_num = parseInt(sec, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor((sec_num - hours * 3600) / 60)
    var seconds = sec_num - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = "0" + hours
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    if (hours == "00") {
      if (minutes == "00") {
        return "0:" + seconds
      } else {
        return minutes + ":" + seconds
      }
    }
    return hours + ":" + minutes + ":" + seconds
  }

  if (!video?.duration) return null
  return toHHMMSS(video.duration)
}

export default formatVideoDuration
