import React, { useRef, useEffect, useState } from "react"
import { arrayOf, bool, string } from "prop-types"
import TextTruncate from "react-text-truncate"
import Skeleton from "react-loading-skeleton"

const CardVideoDescription = ({
  className,
  labelClassName,
  description,
  metadataDescription,
  state,
}) => {
  var dataRef = []
  var dataRows =
    metadataDescription?.length > 0 ? metadataDescription?.length : 1
  for (let i = 0; i < dataRows; ++i) {
    dataRef[i] = useRef(null)
  }
  var isShow = useState(state)

  useEffect(() => {
    dataRef.forEach((ref) => {
      ref?.current?.update()
    })
  }, [isShow])

  if (description) {
    if (metadataDescription?.length > 0) {
      return metadataDescription.map((data, index) => (
        <div className={className} key={index}>
          <div className="line-clamp-1">
            <span className={labelClassName}>
              <TextTruncate text={data} line={1} ref={dataRef[index]} />
            </span>
          </div>
        </div>
      ))
    }

    return (
      <div className={className}>
        <span className={labelClassName}>
          <TextTruncate text={description} line={2} ref={dataRef[0]} />
        </span>
      </div>
    )
  }
  return (
    <div className={className}>
      <span className={labelClassName}>
        <Skeleton count={2} />
      </span>
    </div>
  )
}

CardVideoDescription.propTypes = {
  className: string,
  labelClassName: string,
  description: string,
  state: bool,
  metadataDescription: arrayOf(string),
}

export default CardVideoDescription
