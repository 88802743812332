import { func, node, string } from "prop-types"
import Url from "url"
import Link from "next/link"

const CardVideoLinkWrapper = ({ href, children, onClick }) => {
  if (href) {
    return (
      <Link href={Url.parse(href).path} prefetch={false}>
        <a onClick={onClick}>{children}</a>
      </Link>
    )
  }
  return <>{children}</>
}

CardVideoLinkWrapper.propTypes = {
  href: string,
  children: node,
  onClick: func,
}

export default CardVideoLinkWrapper
