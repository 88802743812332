import arrayToString from "./arrayToString"
import formatVideoMetadata from "./formatVideoMetadata"

const formatVideoMetadataDescription = (video) => {
  if (!video) return null
  let metadataDescriptionArray = []
  const surfers = arrayToString(video.tags?.surfers, "comma")
  surfers && metadataDescriptionArray.push(surfers)
  const metadata = arrayToString(formatVideoMetadata(video), "dot")
  metadata && metadataDescriptionArray.push(metadata)
  return metadataDescriptionArray
}

export default formatVideoMetadataDescription
