import React, { useRef, useEffect, useState } from "react"
import { bool, string } from "prop-types"
import classNames from "classnames"
import TextTruncate from "react-text-truncate"
import Skeleton from "react-loading-skeleton"

const CardVideoTitle = ({ className, title, state }) => {
  const titleRef = useRef(null)
  var isShow = useState(state)

  useEffect(() => {
    titleRef.current?.update()
  }, [isShow])

  return (
    <h2 className={classNames(className)}>
      {title ? (
        <TextTruncate text={title} line={2} ref={titleRef} />
      ) : (
        <Skeleton count={2} className="react-loading-skeleton" />
      )}
    </h2>
  )
}

CardVideoTitle.propTypes = {
  className: string,
  title: string,
  state: bool,
}

export default CardVideoTitle
