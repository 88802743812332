import { array, bool, number, string } from "prop-types"
import { event } from "lib/gtag"

import CardVideoThumbnail from "./Thumbnail"
import CardVideoTitle from "./Title"
import CardVideoDescription from "./Description"
import CardVideoLinkWrapper from "./LinkWrapper"

const CardVideo = ({
  className,
  position,
  title,
  thumbnailScale,
  thumbnail,
  id,
  href,
  description,
  metadataDescription,
  duration,
  state,
}) => {
  const handleCardVideoClick = (id, position) => {
    event({
      action: "Thumbnail",
      category: "Click",
      label: `${id}_${position}`,
    })
  }

  return (
    <div className={className}>
      <CardVideoLinkWrapper
        href={href}
        onClick={() => handleCardVideoClick(id, position)}>
        <CardVideoThumbnail
          image={thumbnail}
          alt={title}
          thumbnailScale={thumbnailScale}
          duration={duration}
          className="relative">
          <span className="absolute bottom-0 left-0 z-20 m-1 text-element-extra-emphasis-dark tabular-nums fs-small-bold">
            {duration}
          </span>
        </CardVideoThumbnail>

        <div className="w-full mt-2 lg:mt-4">
          <CardVideoTitle
            className="text-element-high-emphasis-light fs-small-regular lg:fs-base-interface-bold lg:mb-2 line-clamp-2"
            title={title}
            state={state}
          />

          <CardVideoDescription
            description={description}
            metadataDescription={metadataDescription}
            className="hidden w-full lg:block"
            labelClassName="text-element-medium-emphasis-light fs-small-regular line-clamp-2"
            state={state}
          />
        </div>
      </CardVideoLinkWrapper>
    </div>
  )
}

CardVideo.propTypes = {
  className: string,
  position: number,
  title: string,
  thumbnailScale: number,
  thumbnail: string,
  id: number,
  href: string,
  description: string,
  metadataDescription: array,
  duration: string,
  state: bool,
}

export default CardVideo
